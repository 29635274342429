import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdAirplane } from "react-icons/io";
import { BiCalendar, BiCheckCircle } from "react-icons/bi";
import { BsArrowRight, BsXCircle } from "react-icons/bs";
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { actionTravelRequestView, ACTION_TRAVEL_REQUEST_VIEW_SUCCESS } from './action';
import { actionChangeTravelStatus, ACTION_CHANGE_TRAVEL_STATUS_SUCCESS } from './action';
import { connect } from 'react-redux';
import { generateInitialLettersString } from '../../../../../../utils';
import { bindActionCreators } from 'redux';
import { CANCEL_BY_EMPLOYEE, REQUEST_TRAVEL, REVIEW_BY_SUPERIOR, UNAUTHORIZED } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import RequestHistory from '../../../../../../component/widgets/request_history';
import ChangeStatus from '../../../../../../component/popups/approval_action';
import _ from "lodash";
import { withAuth } from 'react-auth-guard';
import dateFormat from 'dateformat';

const TravelRequestView = (props) => {

    const { register, control, formState: { errors } } = useForm()
    const [travelRequestView, setTravelRequestView] = useState([]);
    const [changeTravelStatus, setChangeTravelStatus] = useState();
    const [requestHistory, setRequestHistory] = useState(null);
    const [showChangeStatus, setShowChangeStatus] = useState(false);

    const initialRender = () => {
        props.actionTravelRequestView({
            id: props.location.state.id,
        });
    }
    useEffect(initialRender, []);

    const onTravelRequestsViewUpdate = () => {
        const { TravelRequestsView } = props;
        if (TravelRequestsView != null) {
            var TravelRequestsViewResponse = Object.assign({}, TravelRequestsView);
            if (TravelRequestsViewResponse.result === true) {
                setTravelRequestView(TravelRequestsViewResponse.response);
                if (TravelRequestsViewResponse.response.request_status.length > 0) {
                    setRequestHistory(TravelRequestsViewResponse.response.request_status);
                }

            } else {
                switch (TravelRequestsViewResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onTravelRequestsViewUpdate, [props.TravelRequestsView])

    const onChangeTravelStatusUpdate = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        const { ChangeTravelStatus } = props;
        if (ChangeTravelStatus != null) {
            var ChangeTravelStatusResponse = Object.assign({}, ChangeTravelStatus);
            if (ChangeTravelStatusResponse.result === true) {
                setChangeTravelStatus(ChangeTravelStatusResponse.response);
                props.actionTravelRequestView({ id: props.location.state.id });
                setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
            } else {
                switch (ChangeTravelStatusResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onChangeTravelStatusUpdate, [props.ChangeTravelStatus])

    return (
        <>
            <ScreenTitle title="Travel Request Detail" />
            <div id="user-profile">
                <div class="row">
                    <div class="col-12">
                        <div class="card profile-with-cover">
                            <div class="card-img-top img-fluid bg-cover height-75" style={{ backgroundColor: '#E3066F88' }}></div>
                            <div class="media profil-cover-details w-100">
                                <div class="media-left pl-2 pt-2">
                                    <a className="profile-image">
                                        {
                                            (travelRequestView != null && travelRequestView.profile_pic != null) ?
                                                <img
                                                    src={travelRequestView.profile_pic}
                                                    className="rounded-circle img-border"
                                                    alt="Card"
                                                    width={100}
                                                    height={100}
                                                    style={{ marginTop: '-65px', border: '5px solid #FFF' }}
                                                />
                                                :
                                                <>
                                                    <div
                                                        className="bg-blue-grey bg-lighten-4 text-bold-500 bg-circle d-flex align-items-center justify-content-center font-large-2"
                                                        style={{ marginTop: '-60px', border: '5px solid #FFF', height: 100, width: 100 }}
                                                    >
                                                        {generateInitialLettersString(String(travelRequestView.full_name))}
                                                    </div>
                                                </>
                                        }
                                    </a>
                                </div>
                                <div class="media-body pt-1 px-2 pb-2">
                                    <div class="row">
                                        <div class="col">
                                            <h3 class="card-title mb-0">
                                                {
                                                    travelRequestView != null && travelRequestView.full_name != null ?
                                                        travelRequestView.full_name : '-'
                                                }
                                            </h3>
                                            <button className="btn btn-sm btn-success text-bold-500 round">
                                                {
                                                    travelRequestView != null && travelRequestView.designation_name != null ?
                                                        travelRequestView.designation_name : '-'
                                                }
                                            </button>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-6">
                                            <h6 className="font-small-2 text-bold-700">Status</h6>
                                            <div className="btn btn-primary round btn-sm px-1 mb-half">
                                                <h6 className="font-small-3 text-bold-500 my-0">
                                                    {
                                                        travelRequestView != null && travelRequestView.user_request_status != null ?
                                                            travelRequestView.user_request_status : '-'
                                                    }
                                                </h6>
                                            </div>
                                            <h4 className="font-small-2">By <span className="text-bold-700 text-justify">
                                                {
                                                    travelRequestView != null && travelRequestView?.status != null &&
                                                        travelRequestView.status != 'cancel_by_employee' ? travelRequestView?.travelUserProfile?.travel_approval_manage_name : travelRequestView?.full_name
                                                }
                                            </span> On: <span className="text-primary">
                                                    {
                                                        travelRequestView != null && travelRequestView.modified_at != null ?
                                                            travelRequestView.modified_at : '-'
                                                    }</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Travel Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        travelRequestView != null && travelRequestView.travel_type != null ?
                                            _.upperFirst(travelRequestView.travel_type) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Trip Type</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        travelRequestView != null && travelRequestView.trip_type != null ?
                                            _.upperFirst(_.replace(travelRequestView.trip_type, '_', ' ')) : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Approval Manager</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        travelRequestView != null && travelRequestView.travelUserProfile?.travel_approval_manage_name != null ?
                                            travelRequestView?.travelUserProfile?.travel_approval_manage_name : '-'
                                    }
                                </h4>
                            </div>
                        </div>

                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Travel Date</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        travelRequestView != null && travelRequestView.travel_date != null ?
                                            dateFormat(travelRequestView.travel_date, 'dd/mm/yyyy') : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        travelRequestView != null && travelRequestView.description != null ?
                                            travelRequestView.description : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Meeting</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">

                                    {
                                        travelRequestView != null && travelRequestView.meeting_id != null ?
                                            travelRequestView.meeting_id : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                            </div>
                            <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                <h4 className="font-medium-1 text-bold-700 mb-0">
                                    {
                                        travelRequestView != null && travelRequestView.remark != null ?
                                            travelRequestView.remark : '-'
                                    }
                                </h4>
                            </div>
                        </div>
                        <div className="ticket-outer p-1 my-2 shadow-sm">
                            <div className="bg-white ticket-view pl-1">
                                {
                                    travelRequestView.travel != null && travelRequestView.travel.length > 0 ?
                                        travelRequestView.travel.map((travel, indexTravel) => (
                                            <div className="row">
                                                <div className="col-12 col-md-2 col-lg-2 date">
                                                    <p>
                                                        <span>
                                                            <IoMdAirplane size={24} />
                                                        </span>
                                                        <time>
                                                            <span>{dateFormat(travel.travel_date, "dd")}</span>
                                                            <span>{dateFormat(travel.travel_date, "mmm yyyy")}</span>
                                                        </time>
                                                    </p>
                                                </div>
                                                <div className="col-12 col-md-10 col-lg-10 px-3 py-1">
                                                    <div className="row px-0 align-items-center mb-half">
                                                        <div className="font-weight-bold col-md-auto">
                                                            <p className="">From</p>
                                                            {travel.arrival}
                                                        </div>
                                                        <span className="d-md-flex col-md-auto">
                                                            <div class="hr-left ml-2 ml-md-0"></div>
                                                            <span class="d-none d-md-block mx-half">
                                                                <BsArrowRight size={18} />
                                                            </span>
                                                            <div class="hr-right ml-2 ml-md-0"></div>
                                                        </span>
                                                        <div className="font-weight-bold col-md-auto">
                                                            <p className="">To</p>
                                                            {travel.departure}
                                                        </div>
                                                    </div>
                                                    <div class="px-0">
                                                        <BiCalendar size={16} />
                                                        <time>
                                                            <span class="ml-half">{dateFormat(travel.travel_date, "dd/mm/yyyy")}</span>
                                                        </time>
                                                    </div>
                                                    <div class="px-0 mt-half">
                                                        <p className="text-bold-700 mb-0">Travel Arrangement</p>
                                                    </div>
                                                    <div class="px-0 mt-half">
                                                        <div className="d-flex flex-row">
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.flight == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Flight
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Flight
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.hotel == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Hotel
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Hotel
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.car == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Rental Car
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Rental Car
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.train_bus == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Train Bus
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Train Bus
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.visa == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Visa
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Visa
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="flex-comumn mr-1">
                                                                {
                                                                    travel.insurance == 1 ?
                                                                        <>
                                                                            <BiCheckCircle size={22} className="mr-half text-primary" />
                                                                            Travel Insurance
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <BsXCircle size={16} className="mr-half" />
                                                                            Travel Insurance
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        travel.travellers != null && travel.travellers.length > 0 ?
                                                            <>
                                                                <p className="text-bold-700 pt-half mb-0">Travel With</p>
                                                                {
                                                                    travel.travellers != null ?
                                                                        _.map(travel.travellers, 'full_name').join(", ") : '-'
                                                                }
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <></>
                                }

                            </div>
                        </div>
                        {
                            requestHistory != null ?
                                <RequestHistory
                                    history={requestHistory}
                                />
                                :
                                <></>
                        }
                        <div className="form-actions border-0 pt-3 float-none">
                            <button
                                className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (props?.location?.state?.type === 'TRAVEL_REQUEST_CREATE_AM_RM' || props?.location?.state?.type === 'TRAVEL_APPROVE_RM') {
                                        props?.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props?.history.goBack();
                                    }
                                }}
                            >Back</button>
                            {
                                travelRequestView != null && (travelRequestView.user_request_status === REVIEW_BY_SUPERIOR) ?
                                    <button
                                        className="btn btn-outline-primary mr-1 shadow round text-uppercase mt-1"
                                        onClick={() => {
                                            Swal.fire({
                                                icon: 'warning',
                                                text: 'Are you sure, Do you want to cancel your Travel request?',
                                                cancelButtonText: "Cancel",
                                                showCancelButton: true,
                                                inputPlaceholder: 'Remark (If Any)',
                                                input: 'text',
                                            }).then(({ isConfirmed, value }) => {
                                                if (isConfirmed) {
                                                    setRequestHistory(null);
                                                    props.actionChangeTravelStatus({
                                                        "id": props.location.state.id,
                                                        "remark": value.length > 0 ? value : '',
                                                        "status": CANCEL_BY_EMPLOYEE,
                                                        "type": REQUEST_TRAVEL
                                                    })
                                                }
                                            })
                                        }}
                                    >Cancel Travel Request</button>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                <ChangeStatus
                    approval={travelRequestView}
                    show={showChangeStatus}
                    type={REQUEST_TRAVEL}
                    handleClose={() => {
                        setShowChangeStatus(false);
                        setRequestHistory(null);
                        props.actionTravelRequestView({
                            id: props.location.state.id,
                        });
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var TravelRequestsView = null;
    var ChangeTravelStatus = null;
    if (
        state.TravelRequestViewReducer != null &&
        Object.keys(state.TravelRequestViewReducer).length > 0 &&
        state.TravelRequestViewReducer.constructor === Object
    ) {
        switch (state.TravelRequestViewReducer.tag) {
            case ACTION_TRAVEL_REQUEST_VIEW_SUCCESS:
                TravelRequestsView = Object.assign({}, state.TravelRequestViewReducer.data);
                delete state.TravelRequestViewReducer;
                return { TravelRequestsView };
            case ACTION_CHANGE_TRAVEL_STATUS_SUCCESS:
                ChangeTravelStatus = Object.assign({}, state.TravelRequestViewReducer.data);
                delete state.TravelRequestViewReducer;
                return { ChangeTravelStatus };
            default:
        }
    }

    return {
        TravelRequestsView,
        ChangeTravelStatus,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionTravelRequestView, actionChangeTravelStatus }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(TravelRequestView));
