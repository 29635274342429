import React, { useState, useEffect } from "react"
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEye } from "react-icons/fi";
import { verifyPermission } from "../../../../../../utils";
import { Template } from "devextreme-react";
import moment from "moment";
import { UNAUTHORIZED } from '../../../../../../constants';
import $ from "jquery";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from "sweetalert2";
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../../utils/preferences';
import { toast } from "react-toastify";
import { withAuth } from 'react-auth-guard';
import { actionReimbursementPoolList, ACTION_REIMBURSEMENT_POOL_LIST_SUCCESS } from './action';

const ReimbursementPool = (props) => {
    const { match } = props;
    const [selectedFromMonth, setSelectedFromMonth] = useState((moment().subtract(2, 'months').format("YYYY-MM")));
    const [selectedToMonth, setSelectedToMonth] = useState(moment().format('YYYY-MM'));
    const [ReimbursementPoolDetails, setReimbursementPoolDetails] = useState([]);
    const columns = [
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "full_name", caption: " Full Employee Name" },
        { dataField: "approval_manager_name", caption: "Manager Name" },
        { dataField: "rem_month", caption: "Month" },
        { dataField: "rem_year", caption: "Year" },
        { dataField: "claim_amount", caption: "Claim Amount" },
        { dataField: "approval_amount", caption: "Approved Amount" },
        { dataField: "physical_document", caption: "Physical Document" },
        { dataField: "manager_action_date", caption: "Manager Approval Date" },
        { dataField: "status", caption: "Status" },

    ];

    const initialRender = () => {
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionReimbursementPoolList(
            {
                user_type: "finance",
                from_month: moment(selectedFromMonth, 'YYYY-MM').format('MM'),
                from_year: moment(selectedFromMonth, 'YYYY-MM').format('YYYY'),
                to_month: moment(selectedToMonth, 'YYYY-MM').format('MM'),
                to_year: moment(selectedToMonth, 'YYYY-MM').format('YYYY')
            }
        );

    }
    useEffect(initialRender, []);

    const renderEmployeeNameCell = (cell) => {
        if (cell?.data?.full_name != null) {
            return <a
            className='text-primary'
            onClick={() => {
                props.history.push({
                    pathname: '/profile',
                    state: {
                        user_id: cell?.data?.user_id,
                    }
                })
            }}
            ><u>{cell?.data?.full_name}</u></a>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission('VIEW_REIMBURSEMENT_APPROVAL') ?
                            <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        const url = `${match.url}/view`;
                                        const stateData = {
                                            id: params.data.id,
                                            user_id: params.data.user_id,
                                            month: params.data.month,
                                            rem_year: params.data.rem_year,
                                            rem_month: params.data.rem_month,
                                            full_name: params.data.full_name,
                                            approval_manager_name: params.data.approval_manager_name
                                        };

                                        localStorage.setItem('detailsData', JSON.stringify(stateData));

                                                const win = window.open(url, '_blank');
                                                if (win) {
                                                    win.focus();
                                                } else {
                                                    console.log('Pop-up blocked. Please allow pop-ups for this site.');
                                                }
                        
                                        // const newWindow = window.open(url, "_blank");
                        
                                        // if (newWindow) {
                                        //     newWindow.onload = () => {
                                        //         newWindow.history.replaceState(stateData, "", url);
                                        //     };
                                        // }
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        :
                                <></>
                        }

                    </Column>
                );
            }
            else {
                if (objColumn.dataField === "reimbursement_id") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            alignment="left"
                            width={150}
                        ></Column>
                    );
                }else if (objColumn.dataField === "full_name") {
                    arrColumns.push(
                        <Column
                            dataField={objColumn.dataField}
                            caption={objColumn.caption}
                            cellRender={renderEmployeeNameCell}
                            visible={objColumn.visible}
                        ></Column>
                    );
                }
                else {
                    if (objColumn.dataField === "amount") {
                        arrColumns.push(
                            <Column
                                dataField={objColumn.dataField}
                                caption={objColumn.caption}
                                alignment="left"
                                width={250}
                            ></Column>
                        );
                    }
                    else {
                        if (objColumn.dataField === "employee_name") {
                            arrColumns.push(
                                <Column
                                    dataField={objColumn.dataField}
                                    caption={objColumn.caption}
                                    alignment="left"
                                    width={300}
                                ></Column>
                            );
                        }
                        else {
                            arrColumns.push(
                                <Column
                                    dataField={objColumn.dataField}
                                    caption={objColumn.caption}
                                ></Column>
                            )
                        }
                    }
                }
            }
        });
        return arrColumns;
    };
    const onReimbursementPoolListUpdate = () => {
        const { ReimbursementPoolList } = props;
        if (ReimbursementPoolList != null) {
            setReimbursementPoolDetails([]);
            var ReimbursementPoolListResponse = Object.assign({}, ReimbursementPoolList);
            if (ReimbursementPoolListResponse.result === true) {
                setReimbursementPoolDetails(ReimbursementPoolListResponse.response.reimbursement_list);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ReimbursementPoolListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onReimbursementPoolListUpdate, [props.ReimbursementPoolList])

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                        <h5 className="content-header-title text-capitalize mb-0">Reimbursement Pool {ReimbursementPoolDetails?.length === 0 ? '' : `(${ReimbursementPoolDetails?.length})`}
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form nonValidate autoComplete="Off" >
                                    <div className='form-body'>
                                        <div className='row'>
                                            <div className='col-12 col-md-2 col-lg-3'>
                                                <label className="label-control primary"> From Month</label>
                                                <input
                                                    type="month"
                                                    className="form-control"
                                                    id="from_month"
                                                    name="from_year"
                                                    placeholderText="Select Month"
                                                    defaultValue={selectedFromMonth}
                                                    max={moment().format('YYYY-MM')}
                                                    onChange={(event) => {
                                                        setSelectedFromMonth(event.target.value)
                                                        if (moment(event.target.value) <= moment(selectedToMonth)) {
                                                            props.actionReimbursementPoolList({
                                                                user_type: "finance",
                                                                from_month: moment(event.target.value, 'YYYY-MM').format('MM'),
                                                                from_year: moment(event.target.value, 'YYYY-MM').format('YYYY'),
                                                                to_month: moment(selectedToMonth, 'YYYY-MM').format('MM'),
                                                                to_year: moment(selectedToMonth, 'YYYY-MM').format('YYYY')
                                                            })
                                                        } else {
                                                            setSelectedToMonth('')
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "From month and year can't be greater than To month and year",
                                                                icon: 'error'
                                                            }).then(success => {
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='col-12 col-md-2 col-lg-3'>
                                                <label className="label-control primary"> To Month</label>
                                                <input
                                                    type="month"
                                                    className="form-control"
                                                    id="to_month"
                                                    name="to_year"
                                                    placeholderText="Select Month"
                                                    defaultValue={selectedToMonth}
                                                    max={moment().format('YYYY-MM')}
                                                    onChange={(event) => {
                                                        setSelectedToMonth(event.target.value)
                                                        if (moment(event.target.value) >= moment(selectedFromMonth)) {
                                                            props.actionReimbursementPoolList({
                                                                user_type: "finance",
                                                                from_month: moment(selectedFromMonth, 'YYYY-MM').format('MM'),
                                                                from_year: moment(selectedFromMonth, 'YYYY-MM').format('YYYY'),
                                                                to_month: moment(event.target.value, 'YYYY-MM').format('MM'),
                                                                to_year: moment(event.target.value, 'YYYY-MM').format('YYYY')
                                                            })
                                                        } else {
                                                            setSelectedFromMonth('')
                                                            Swal.fire({
                                                                title: 'Error',
                                                                text: "To month and year can't be less than from month and year",
                                                                icon: 'error'
                                                            }).then(success => {
                                                            })
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <DataGrid
                                    dataSource={ReimbursementPoolDetails}
                                    showBorders={true}
                                    columnAutoWidth={true}
                                    export={{
                                        fileName: "Reimbursement Pool List",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onToolbarPreparing={(e) => {
                                        e.toolbarOptions.items.unshift({
                                            location: 'before',
                                            template: 'totalGroupCount',

                                        })
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                    <Template name="totalGroupCount" render={() => {
                                        return (
                                            <></>
                                        );
                                    }} />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    var ReimbursementPoolList = null;

    if (
        state.ReimbursementPoolListReducer != null &&
        Object.keys(state.ReimbursementPoolListReducer).length > 0 &&
        state.ReimbursementPoolListReducer.constructor === Object
    ) {
        switch (state.ReimbursementPoolListReducer.tag) {
            case ACTION_REIMBURSEMENT_POOL_LIST_SUCCESS:
                ReimbursementPoolList = Object.assign({}, state.ReimbursementPoolListReducer.data);
                delete state.ReimbursementPoolListReducer;
                return { ReimbursementPoolList };
            default:
        }
    }

    return {
        ReimbursementPoolList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionReimbursementPoolList }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReimbursementPool))
