import React, { useEffect, useState } from "react"
import { FiFileText } from "react-icons/fi";
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE, setData } from '../../../../../../utils/preferences';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ERROR, PRIMARY_COLOR, UNAUTHORIZED } from "../../../../../../constants";
import ApprovalMobileClaim from '../../../../../../component/form/approval_mobile_claim';
import ApprovalConveyanceClaim from '../../../../../../component/form/approval_conveyance_claim';
import ApprovalOtherClaim from '../../../../../../component/form/approval_other_claim';
import RejectClaims from '../../../../../../component/form/reject_claim';
import _ from "lodash";
import $ from 'jquery';
import { toast } from 'react-toastify';
import { useForm, Controller } from "react-hook-form";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ErrorLabel from "../../../../../../component/form/error_label";
import Swal from "sweetalert2";
import { withAuth } from 'react-auth-guard';
import { verifyPermission } from "../../../../../../utils";
import moment from "moment";
import { actionReimbursementView, ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS } from './action';
import { actionReimbursementPoolViewChangeStatus, ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS } from './action';
import { actionReimbursementPoolPhysicalDocument, ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS } from './action';
import { useLocation } from 'react-router-dom';


const ReimbursementPoolView = (props) => {

    const { handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [showmobilepoolclaim, setShowmobilepoolclaim] = useState(false);
    const [showconveyancepoolclaim, setShowconveyancepoolclaim] = useState(false);
    const [showotherpoolclaim, setShowotherpoolclaim] = useState(false);
    const [showrejectclaim, setShowRejectClaim] = useState(false);
    const [reimbursementPoolDetail, setReimbursementPoolDetail] = useState(null);
    const [objReimbursementItem, setObjReimbursementItem] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [totalApprovedAmount, setTotalApprovedAmount] = useState();
    const [remTitle, setRemTitle] = useState(null);
    const [totalFinanceApprovedAmount, setTotalFinanceApprovedAmount] = useState(null);
    const [reimbursementId, setReimbursementId] = useState(null);
    const [physicalDocument, setPhysicalDocument] = useState([]);
    const [selectPhysicalDocument, setSelectPhysicalDocument] = useState(null);
    const location = useLocation()
    const [data, setData] = useState(window.history.state)    
    //   useEffect(() => {
    //         // Use location.state if coming from the same tab, otherwise fallback to history.state
    //         setData(location.state || window.history.state || {});
    //     }, [location.state]);

    const initialRender = () => {

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        setData(JSON.parse(localStorage.getItem('detailsData')))
        props.actionReimbursementPoolPhysicalDocument();
       
      
    }
    useEffect(initialRender, []);

    useEffect(()=>{
        if (data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE') {
            props.actionReimbursementView({
                id: data?.id,
            });
        } else {
            props.actionReimbursementView({
                user_id: data?.user_id,
                month: data?.month,
                year: data?.rem_year
            });
        }
    },[data])

    const onReimbursementPoolChangeStatusUpdate = () => {
        const { ReimbursementPoolViewChangeStatus } = props;
        var ReimbursementPoolViewChangeStatusResponse = Object.assign({}, ReimbursementPoolViewChangeStatus);
        if (ReimbursementPoolViewChangeStatusResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Status Change Successfully");
            if (data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE') {
                props.actionReimbursementView({
                    id: data?.id,
                });
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.actionReimbursementView({
                    user_id: data?.user_id,
                    month: data?.month,
                    year: data?.rem_year
                });
                props.history.goBack();
            }

        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementPoolViewChangeStatusResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementPoolChangeStatusUpdate, [window.history.state?.ReimbursementPoolViewChangeStatus]);

    const onReimbursementPoolViewUpdate = () => {
        const { ReimbursementPoolView } = props;
        var ReimbursementPoolViewResponse = Object.assign({}, ReimbursementPoolView);
        if (ReimbursementPoolViewResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setReimbursementPoolDetail(ReimbursementPoolViewResponse?.response);
            setReimbursementId(ReimbursementPoolViewResponse?.response?.id);
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ReimbursementPoolViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementPoolViewUpdate, [props.ReimbursementPoolView]);

    useEffect(() => {
        if (physicalDocument != null && physicalDocument.length > 0) {
            if (reimbursementPoolDetail != null && reimbursementPoolDetail?.physical_document != null) {
                setValue('physical_document', reimbursementPoolDetail?.physical_document)
            }
        }
    }, [physicalDocument, reimbursementPoolDetail])

    const onReimbursementPoolPhysicalDocumentUpdate = () => {
        const { ReimbursementPoolPhysicalDocument } = props;
        var ReimbursementPoolPhysicalDocumentResponse = Object.assign({}, ReimbursementPoolPhysicalDocument);
        if (ReimbursementPoolPhysicalDocumentResponse.result === true) {
            setPhysicalDocument(ReimbursementPoolPhysicalDocumentResponse.response);
        } else {
            switch (ReimbursementPoolPhysicalDocumentResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setPhysicalDocument([]);
                    break;
                default:
            }
        }
    }
    useEffect(onReimbursementPoolPhysicalDocumentUpdate, [props.ReimbursementPoolPhysicalDocument]);

    const onSubmit = (data) => {

    }

    return (
        <>
            {/* <Screen_title title="Reimbursement" /> */}
            <div className="content-header row mb-1">
                <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
                    <h5 className="content-header-title text-capitalize mb-0">Reimbursement
                    </h5>
                </div>
                <div className="content-header-right col-12 col-md-12 col-lg-12">
                    <ul class="nav nav-pills float-right">
                        <li class="nav-item">
                            <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE') {
                                        props.history.push({
                                            pathname: '/notification'
                                        })
                                    } else {
                                        props.history.goBack();
                                    }
                                }}

                            > Back</button>
                        </li>
                    </ul>
                </div>
            </div>
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {/* <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Employee Code</label>
                                                <div className='mt-1'>
                                                    <label className="label-control font-weight-bold mr-1">{reimbursementPoolDetail != null ? reimbursementPoolDetail?.employee_code : '-'}</label>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Employee Name</label>
                                                <div className='mt-1'>
                                                    <label className="label-control  font-weight-bold mr-1">{
                                                        data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE' ? reimbursementPoolDetail?.creator_name :
                                                            data?.full_name != null ? data?.full_name : '-'
                                                    }</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Manager Name</label>
                                                <div className='mt-1'>
                                                    <label className="label-control  font-weight-bold mr-1">{
                                                        data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE' ? reimbursementPoolDetail?.reim_approval_manager_name :
                                                            data?.approval_manager_name != null ? data?.approval_manager_name : '-'
                                                    }</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Month of Reimbursement </label>
                                                <div className='mt-1'>
                                                    <label className="label-control  font-weight-bold mr-1">{
                                                        data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE' && moment(reimbursementPoolDetail?.rem_month).isValid() && reimbursementPoolDetail?.rem_month != null && reimbursementPoolDetail?.rem_year != null ? moment(reimbursementPoolDetail?.rem_month, 'M').format('MMMM') + ' ' + reimbursementPoolDetail?.rem_year :
                                                            data?.rem_month != null && data?.rem_year != null ? data?.rem_month + ' ' + data?.rem_year : '-'
                                                    }</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label className="label-control mr-1">Status: <b>{reimbursementPoolDetail != null ? _.upperFirst(reimbursementPoolDetail?.status.replace('_', ' ').replace('_', ' ').replace('_', ' ')) : '-'}</b></label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-header">
                    <h3 className="content-header-title text-capitalize mb-2"> Reimbursement  Details</h3>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form>
                                    <div className='row'>
                                        <div class="col-12 col-md-12 col-lg-12 ">
                                            <h4 class="align-items-center form-section  black text-bold-700">
                                                Reimbursement Overview
                                            </h4>
                                            {
                                                reimbursementPoolDetail?.all_details != null && reimbursementPoolDetail?.all_details.length > 0 ?
                                                    reimbursementPoolDetail?.all_details.map((item, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                                    <div className='row'>
                                                                        <div className="col-12 col-md-3 col-lg-2">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Reimbursement for</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control text-uppercase font-weight-bold">{item.title}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            verifyPermission('EDIT_REIMBURSEMENT_APPROVAL') ?
                                                                                <div>
                                                                                    {
                                                                                        item.view_type === "MOBILE" ?
                                                                                            <div className="col-12 col-md-3 col-lg-1">
                                                                                                <div className="form-group">
                                                                                                    <button
                                                                                                        id="edit_reimbursement_pool"
                                                                                                        name="edit_reimbursement_pool"
                                                                                                        type="button"
                                                                                                        className="btn px-0 primary cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            setObjReimbursementItem(item.list);
                                                                                                            setTotalAmount(item.total_claim_amount);
                                                                                                            setTotalApprovedAmount(item.total_approved_amount);
                                                                                                            setTotalFinanceApprovedAmount(item.total_finance_approved_amount);
                                                                                                            setRemTitle(item.title);
                                                                                                            setShowmobilepoolclaim(true)
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiFileText size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        item.view_type === "CONVENIENCE" ?
                                                                                            <div className="col-12 col-md-3 col-lg-1">
                                                                                                <div className="form-group">
                                                                                                    <button
                                                                                                        id="edit_reimbursement_pool"
                                                                                                        name="edit_reimbursement_pool"
                                                                                                        type="button"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#approval-conveyance-modal"
                                                                                                        className="btn px-0 primary cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            setObjReimbursementItem(item.list);
                                                                                                            setTotalAmount(item.total_claim_amount);
                                                                                                            setTotalApprovedAmount(item.total_approved_amount);
                                                                                                            setTotalFinanceApprovedAmount(item.total_finance_approved_amount);
                                                                                                            setRemTitle(item.title);
                                                                                                            setShowconveyancepoolclaim(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiFileText size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        item.view_type === "OTHERS" ?
                                                                                            <div className="col-12 col-md-3 col-lg-1">
                                                                                                <div className="form-group">
                                                                                                    <button
                                                                                                        id="edit_reimbursement_pool"
                                                                                                        name="edit_reimbursement_pool"
                                                                                                        type="button"
                                                                                                        data-toggle="modal"
                                                                                                        data-target="#approval-other-modal"
                                                                                                        className="btn px-0 primary cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            setObjReimbursementItem(item.list);
                                                                                                            setTotalAmount(item.total_claim_amount);
                                                                                                            setTotalApprovedAmount(item.total_approved_amount);
                                                                                                            setTotalFinanceApprovedAmount(item.total_finance_approved_amount);
                                                                                                            setRemTitle(item.title);
                                                                                                            setShowotherpoolclaim(true);
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiFileText size={20} />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Reimbursement Amount</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control"><b>{item.total_claim_amount != null ?
                                                                                        <span><span>&#8377;</span> {Number(item.total_claim_amount).toFixed(2)} </span> : "--"}</b></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Manager Approved Amount</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control"><b>{item.total_approved_amount != null ?
                                                                                        <span><span>&#8377;</span> {Number(item.total_approved_amount).toFixed(2)} </span> : "--"}</b></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-3 col-lg-3">
                                                                            <div className="form-group">
                                                                                <label className="label-control">Finance Approved Amount</label>
                                                                                <div className="form-group">
                                                                                    <label className="label-control"><b>{item.total_finance_approved_amount != null ?
                                                                                        <span><span>&#8377;</span> {Number(item.total_finance_approved_amount).toFixed(2)} </span> : "--"}</b></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div >
                                                                </div>
                                                                <h4 class="align-items-center form-section px-1 black"></h4>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                            }
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                <div className='row mt-1 '>
                                                    <div className="col-12 col-md-3 col-lg-3">
                                                        <div className="form-group">
                                                            <label className="label-control">Reimbursement Amount</label>
                                                            <div className="form-group">
                                                                <label className="label-control"><b>{reimbursementPoolDetail != null && reimbursementPoolDetail.claim_amount != null ?
                                                                    <span><span>&#8377;</span> {Number(reimbursementPoolDetail.claim_amount).toFixed(2)} </span> : "--"}</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="label-control">Manager Approved Amount</label>
                                                            <div className="form-group">
                                                                <label className="label-control"><b>{reimbursementPoolDetail != null && reimbursementPoolDetail.approval_amount != null ?
                                                                    <span><span>&#8377;</span> {Number(reimbursementPoolDetail.approval_amount).toFixed(2)} </span> : "--"}</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="label-control">Finance Approved Amount</label>
                                                            <div className="form-group">
                                                                <label className="label-control"><b>{reimbursementPoolDetail != null && reimbursementPoolDetail.finance_approval_amount != null ?
                                                                    <span><span>&#8377;</span> {Number(reimbursementPoolDetail.finance_approval_amount).toFixed(2)} </span> : "--"}</b></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="align-items-center form-section px-1 black"></h4>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Physical Document</label>
                                                    <Controller
                                                        name="physical_document"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="physical_document"
                                                                name="physical_document"
                                                                dataSource={physicalDocument}
                                                                fields={{ text: "title", value: "value" }}
                                                                allowFiltering={false}
                                                                placeholder="Select Physical Document"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemdata?.value)
                                                                        setSelectPhysicalDocument(event.itemdata?.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {errors.physical_document && <ErrorLabel message={errors.physical_document?.message} />}
                                                </div>
                                            </div>
                                            {
                                                reimbursementPoolDetail != null && reimbursementPoolDetail?.status === 'approved by manager' && reimbursementPoolDetail?.all_details != null && reimbursementPoolDetail?.all_details.length > 0 ?
                                                    <div className="form-actions border-0 float-none">
                                                        <button
                                                            id="reimbursement_pool_approved"
                                                            name="reimbursement_pool_approved"
                                                            type="button"
                                                            className="btn btn-primary mr-1 mt-0 shadow  text-uppercase"
                                                            onClick={() => {
                                                                const nullAmount = reimbursementPoolDetail?.all_details.filter(obj => obj.total_finance_approved_amount === null);
                                                                if (nullAmount.length > 0) {
                                                                    Swal.fire({
                                                                        title: 'Error',
                                                                        icon: 'error',
                                                                        html: "Please insert apporval amount in all entries or inserted approval amount should be less than or equal to Reimbursement amount",
                                                                        confirmButtonColor: PRIMARY_COLOR
                                                                    }).then((result) => {
                                                                    });
                                                                } else {
                                                                    Swal.fire({
                                                                        icon: 'warning',
                                                                        text: 'Are you sure, you want to approve this Reimbursement ?',
                                                                        cancelButtonText: "Cancel",
                                                                        showCancelButton: true,
                                                                        inputPlaceholder: 'Reason',
                                                                        input: 'text',
                                                                    }).then(({ isConfirmed, value }) => {
                                                                        if (isConfirmed) {
                                                                            $("#progress-loader").removeClass("hidden");
                                                                            props.actionReimbursementPoolViewChangeStatus({
                                                                                "reimbursement_id": data?.id,
                                                                                "status": "approved_by_finance_manager",
                                                                                "remark": value.length > 0 ? value : '',
                                                                                "is_physical_document": true,
                                                                                "physical_document": selectPhysicalDocument != null ? selectPhysicalDocument : 'pending'
                                                                            })

                                                                        }
                                                                    })
                                                                }


                                                            }}
                                                        >
                                                            Approve
                                                        </button>

                                                        <button
                                                            id="reimbursement_pool_reject"
                                                            name="reimbursement_pool_reject"
                                                            type="button"
                                                            className=" btn btn-outline-secondary mr-1 mt-0 shadow  text-uppercase"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    icon: 'warning',
                                                                    text: 'Are you sure, you want to reject this Reimbursement ?',
                                                                    cancelButtonText: "Cancel",
                                                                    showCancelButton: true,
                                                                    inputPlaceholder: 'Reason',
                                                                    input: 'text',
                                                                    customClass: {
                                                                        validationMessage: 'my-validation-message'
                                                                    },
                                                                    preConfirm: (value) => {
                                                                        if (!value) {
                                                                            Swal.showValidationMessage(
                                                                                'Reason is required'
                                                                            )
                                                                        }
                                                                    }
                                                                }).then(({ isConfirmed, value }) => {
                                                                    if (isConfirmed) {
                                                                        $("#progress-loader").removeClass("hidden");
                                                                        props.actionReimbursementPoolViewChangeStatus({
                                                                            "reimbursement_id": data?.id,
                                                                            "status": "reject_by_finance_manager",
                                                                            "remark": value.length > 0 ? value : '',
                                                                            "is_physical_document": true,
                                                                            "physical_document": selectPhysicalDocument != null ? selectPhysicalDocument : 'pending'
                                                                        })
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            Reject
                                                        </button>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                reimbursementPoolDetail != null && reimbursementPoolDetail?.status === 'approved by finance manager' ?
                                                    <div className="form-actions border-0 float-none">
                                                        <button
                                                            id="reimbursement_pool_update"
                                                            name="reimbursement_pool_update"
                                                            type="button"
                                                            className="btn btn-primary mr-1 mt-0 shadow  text-uppercase"
                                                            onClick={() => {
                                                                props.actionReimbursementPoolViewChangeStatus({
                                                                    "reimbursement_id": data?.id,
                                                                    "is_physical_document": true,
                                                                    "physical_document": selectPhysicalDocument != null ? selectPhysicalDocument : 'pending'
                                                                })
                                                            }}
                                                        >
                                                            Update
                                                        </button>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
            <ApprovalMobileClaim
                data={objReimbursementItem}
                show={showmobilepoolclaim}
                amount={totalAmount}
                approvedAmount={totalApprovedAmount}
                financeApprovedAmount={totalFinanceApprovedAmount}
                details={reimbursementPoolDetail}
                userType='finance'
                id={reimbursementId}
                remTitle={remTitle}
                handleClose={(newMobileClaim) => {
                    setShowmobilepoolclaim(false)
                    if (data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE') {
                        props.actionReimbursementView({
                            id: data?.id,
                        });
                    } else {
                        props.actionReimbursementView({
                            user_id: data?.user_id,
                            month: data?.month,
                            year: data?.rem_year
                        });
                    }
                }}
            />
            <ApprovalConveyanceClaim
                data={objReimbursementItem}
                show={showconveyancepoolclaim}
                amount={totalAmount}
                approvedAmount={totalApprovedAmount}
                financeApprovedAmount={totalFinanceApprovedAmount}
                claimDetail={reimbursementPoolDetail}
                userType='finance'
                id={reimbursementId}
                remTitle={remTitle}
                handleClose={(newMobileClaim) => {
                    setShowconveyancepoolclaim(false)
                    if (data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE') {
                        props.actionReimbursementView({
                            id: data?.id,
                        });
                    } else {
                        props.actionReimbursementView({
                            user_id: data?.user_id,
                            month: data?.month,
                            year: data?.rem_year
                        });
                    }
                }}
            />
            <ApprovalOtherClaim
                data={objReimbursementItem}
                show={showotherpoolclaim}
                details={reimbursementPoolDetail}
                amount={totalAmount}
                approvedAmount={totalApprovedAmount}
                financeApprovedAmount={totalFinanceApprovedAmount}
                userType='finance'
                id={reimbursementId}
                remTitle={remTitle}
                handleClose={(newMobileClaim) => {
                    setShowotherpoolclaim(false)
                    if (data?.type === 'REIMBURSMENT_REQUEST_APPROVED_BY_MANAGER_TO_FINANCE') {
                        props.actionReimbursementView({
                            id: data?.id,
                        });
                    } else {
                        props.actionReimbursementView({
                            user_id: data?.user_id,
                            month: data?.month,
                            year: data?.rem_year
                        });
                    }
                }}
            />
            <RejectClaims
                show={showrejectclaim}
                handleClose={(newMobileClaim) => {
                    setShowRejectClaim(false)

                }}

            />
        </>
    )
}
const mapStateToProps = (state) => {
    var ReimbursementPoolView = null;
    var ReimbursementPoolViewChangeStatus = null;
    var ReimbursementPoolPhysicalDocument = null;

    if (
        state.ReimbursementViewReducer != null &&
        Object.keys(state.ReimbursementViewReducer).length > 0 &&
        state.ReimbursementViewReducer.constructor === Object
    ) {
        switch (state.ReimbursementViewReducer.tag) {
            case ACTION_REIMBURSEMENT_POOL_VIEW_SUCCESS:
                ReimbursementPoolView = Object.assign({}, state.ReimbursementViewReducer.data);
                delete state.ReimbursementViewReducer;
                return { ReimbursementPoolView };
            case ACTION_REIMBURSEMENT_POOL_VIEW_CHANGE_STATUS_SUCCESS:
                ReimbursementPoolViewChangeStatus = Object.assign({}, state.ReimbursementViewReducer.data);
                delete state.ReimbursementViewReducer;
                return { ReimbursementPoolViewChangeStatus };
            case ACTION_REIMBURSEMENT_POOL_PHYSICAL_DOCUMENT_SUCCESS:
                ReimbursementPoolPhysicalDocument = Object.assign({}, state.ReimbursementViewReducer.data);
                delete state.ReimbursementViewReducer;
                return { ReimbursementPoolPhysicalDocument };
            default:
        }
    }
    return {
        ReimbursementPoolView,
        ReimbursementPoolViewChangeStatus,
        ReimbursementPoolPhysicalDocument
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionReimbursementView,
        actionReimbursementPoolViewChangeStatus,
        actionReimbursementPoolPhysicalDocument
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ReimbursementPoolView));
